import { useEffect, useState } from "react";
import TextInput from "../forms/inputs/TextInput";
import Button from "../Button";
import { Circles } from "react-loader-spinner";
import { BASE_URL, colors } from "../../settings";
import WhiteLogo from "../images/logos/WhiteLogo";
import clsx from "clsx";
import { Link } from "react-router-dom";
import axios from "axios";
import DeleteTrigger from "../DeleteTrigger";


export default function BrandForm({ title, brand, onSubmit, loading = false, error, edit = false, userToken }) {
    const labelClass = "text-24 mb-2 block";
    const [name, setName] = useState(brand.name);
    const [url, setUrl] = useState(brand.png_url ? brand.png_url : brand.jpg_url);
    const [catalogsManager, setCatalogsManager] = useState(false);
    const [catalogs, setCatalogs] = useState(brand.catalogs ?? []);
    const [catalogError, setCatalogError] = useState(null);
    const [catalogsLoading, setCatalogsLoading] = useState(false);
    const [catalogsConfirm, setCatalogsConfirm] = useState(false);

    function getNewId() {
        if (catalogs.length <= 0) {
            return 1;
        } else {
            return Math.max(...catalogs.map(v => v.id)) + 1;
        }
    }

    function addCatalog() {
        setCatalogs([
            ...catalogs,
            {
                "id": getNewId(),
                "url": "",
                "new": true
            }
        ])
    }

    function editCatalog(id, url) {
        const catalog = catalogs.find(v => v.id === id);
        catalog.url = url;
        setCatalogs([...catalogs.map((v) => {
            if (v.id === id) {
                return catalog;
            } else {
                return v;
            }
        })]);
    }

    async function submitCatalogs(e) {
        e.preventDefault();
        setCatalogsLoading(true);
        var errors = false;
        for (let catalog of catalogs) {
            const data = {
                "url": catalog.url
            };
            if (catalog.new) {
                try {
                    await axios.post(`${BASE_URL}/brands/${brand.id}/catalogs`, data, {
                        headers: {
                            "Authorization": `Bearer ${userToken}`,
                            "Accept": "application/json",
                            "Content-Type*": "application/json"
                        }
                    })
                } catch (err) {
                    errors = true;
                    console.log(err);
                    setCatalogError(err.response.data.message);
                    break;
                }
            } else {
                try {
                    await axios.put(`${BASE_URL}/catalogs/${catalog.id}`, data, {
                        headers: {
                            "Authorization": `Bearer ${userToken}`,
                            "Accept": "application/json",
                            "Content-Type": "application/json"
                        }
                    })
                } catch (err) {
                    errors = true;
                    console.log(err);
                    setCatalogError(err.response.data.message);
                    break;
                }
            }
        }
        setCatalogsLoading(false);
        if (!errors) {
            setCatalogsConfirm(true);
        }
    }

    async function deleteCatalog(catalog) {
        console.log(catalog);
        console.log(userToken);
        if (!catalog.new) {
            try {
                await axios.delete(`${BASE_URL}/catalogs/${catalog.id}`, {
                    headers: {
                        "Authorization": `Bearer ${userToken}`
                    }
                });
            } catch (err) {
                console.log(err);
            }
        }
        setCatalogs([...catalogs.filter(v => v.id !== catalog.id)]);
    }

    useEffect(() => {
        setCatalogError(null);
        setCatalogsConfirm(false);
    }, [catalogsManager]);

    return (
        <div className="w-dvw h-dvh py-10 px-6 relative tablet:pt-32">
            <div className="w-full bg-gradient-to-r from-pharmagency-blue to-pharmagency-cyan py-6 px-8 flex items-center justify-between absolute top-0 left-0 rounded-b-3xl">
                <div className="mr-8">
                    <WhiteLogo className={"max-w-[100px] mobile:max-w-[60px]"} />
                </div>
                <div className={clsx(
                    "w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-[800px] small-computer:static small-computer:translate-x-0 small-computer:translate-y-0 flex items-center"
                )}>
                    <h1 className="text-[2rem] mobile:text-24 text-center font-medium text-pharmagency-white w-full">{title}</h1>
                </div>
                <Link className="ml-8" to={"/admin/marques"}>
                    <Button className={"text-pharmagency-white border border-pharmagency-white text-14 transition-all hover:bg-pharmagency-white hover:text-pharmagency-cyan font-medium whitespace-nowrap"}>
                        Retour
                    </Button>
                </Link>
            </div>
            {!catalogsManager ?
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full tablet:static tablet:translate-x-0 tablet:translate-y-0 tablet:mt-16">
                    <form className="flex items-center justify-center px-6 tablet:flex-col-reverse" onSubmit={(e) => {
                        if (!loading) {
                            onSubmit(e, name, url);
                        }
                    }}>
                        <div className="w-full max-w-[550px] flex flex-col">
                            <div className="mb-8">
                                <label className={labelClass}>Nom de la marque</label>
                                <TextInput type={"text"} required className={"border-2 border-pharmagency-light-grey max-w-[550px] w-full"} backgroundClass="bg-pharmagency-lighter-grey" placeholder={"Nom de la marque"} setValue={setName} value={name} />
                            </div>
                            <div>
                                <label className={labelClass}>URL</label>
                                <TextInput type={"url"} required className={"border-2 border-pharmagency-light-grey max-w-[550px] w-full"} backgroundClass="bg-pharmagency-lighter-grey" placeholder={"URL"} setValue={setUrl} value={url} />
                            </div>
                            {error && <p className="text-pharmagency-red mt-8 tablet:text-center">{error}</p>}
                            <div className="mt-8 flex items-center">
                                <Button type="submit" className={"bg-pharmagency-cyan text-pharmagency-white transition-all hover:bg-pharmagency-blue w-fit tablet:self-center"}>
                                    {loading ? <Circles
                                        width={20}
                                        height={20}
                                        color={colors.white} />
                                        : "Valider"}
                                </Button>
                                {edit && <Button onClick={() => {
                                    setCatalogsManager(true);
                                }} className={"bg-pharmagency-blue text-pharmagency-white ml-3 transition-all border border-transparent hover:border-pharmagency-blue hover:bg-pharmagency-white hover:text-pharmagency-blue"}>Catalogues</Button>}
                            </div>
                        </div>
                        <div className="max-w-[300px] overflow-hidden rounded-3xl shadow-2xl ml-32 tablet:ml-0 tablet:mb-16 tablet:max-w-[250px]">
                            <img src={url} alt="Logo" className="w-full aspect-square object-cover" />
                        </div>
                    </form>
                </div>
                :
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full px-3 max-w-[900px]">
                    <form className="w-full" onSubmit={submitCatalogs}>
                        <div className="w-full mb-10">
                            {catalogs.map(
                                v => <div className="flex items-center my-3">
                                    <TextInput type={"url"} value={v.url} placeholder={"URL du catalogue"} className={"border-2 border-pharmagency-light-grey w-full w-full"} backgroundClass="bg-pharmagency-lighter-grey" setValue={(value) => {
                                        editCatalog(v.id, value);
                                    }} />
                                    <DeleteTrigger message={"Voulez-vous supprimer ce catalogue ?"} className={"mobile:w-[24px] w-[32px] relative ml-3"} onClick={() => {
                                        deleteCatalog(v);
                                    }} />
                                </div>
                            )}
                        </div>
                        {catalogError && <p className="text-pharmagency-red mb-4 tablet:text-center">{catalogError}</p>}
                        {catalogsConfirm && <p className="text-pharmagency-blue mb-4 tablet:text-center">{`Les catalogues de la marque ${brand.name} ont été enregistrés.`}</p>}
                        <div className="flex items-center justify-center mobile:flex-col mobile:w-fit mobile:mx-auto">
                            <Button onClick={() => {
                                setCatalogsManager(false);
                            }} className={"border border-pharmagency-blue text-pharmagency-blue transition-all hover:bg-pharmagency-blue hover:text-pharmagency-white mobile:w-full"}>
                                Retour
                            </Button>
                            <Button onClick={() => {
                                addCatalog();
                            }} className={"bg-pharmagency-blue text-pharmagency-white transition-all border border-transparent hover:border-pharmagency-blue hover:bg-pharmagency-white hover:text-pharmagency-blue mobile:w-full mx-3 mobile:my-3"}>Ajouter un catalogue</Button>
                            {catalogs.length > 0 &&
                                <Button type="submit" className={"bg-pharmagency-cyan text-pharmagency-white transition-all hover:bg-pharmagency-blue w-fit tablet:self-center mobile:w-full"}>
                                    {catalogsLoading ? <Circles
                                        width={20}
                                        height={20}
                                        color={colors.white} />
                                        : "Valider"}
                                </Button>}
                        </div>
                    </form>
                </div>}
        </div>
    )
}